import {Injectable} from '@angular/core';
import {RundfunkAnstalt} from "../../model/document.model";

@Injectable({
  providedIn: 'root'
})
export class RfaCache {

  private readonly LS_SELECTED_RFA = 'selectedRfa';

  /**
   * Lädt die ausgewählte RFA, wenn verfügbar, aus dem Cache
   */
  public getSelectionFromCache() {
    let cachedRfa: RundfunkAnstalt | undefined = undefined;
    try {
      const selectedRfaFromLocalStorage = localStorage.getItem(this.LS_SELECTED_RFA);
      if (selectedRfaFromLocalStorage) {
        // console.log('Retrieved Rfa from Cache: ', selectedRfaFromLocalStorage)
        cachedRfa = JSON.parse(selectedRfaFromLocalStorage);
      }
    } catch (e) {
      console.log(`ERROR: getSelectedRfa:`, e)
    }
    return cachedRfa;
  }

  public cacheSelection(rfa: RundfunkAnstalt) {
    localStorage.setItem(this.LS_SELECTED_RFA, JSON.stringify(rfa));
    return rfa;
  }
}
