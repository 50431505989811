import {Component, Input, OnDestroy} from '@angular/core';
import {Container, Tab, TextArea} from "../../../../model/layout.model";
import {LayoutElementUtil} from "../../../../service/layout/layout-element.service";
import {ScreenSizeObserverService} from "../../../../service/layout/screen-size-observer.service";
import {FileUploadComponent} from '../../../layout/element/file-upload/file-upload.component';
import {DatetimePickerComponent} from '../../../layout/element/datetime-picker/datetime-picker.component';
import {TextFieldComponent} from '../../../layout/element/text-field/text-field.component';
import {TerminalListComponent} from '../../../layout/element/terminal-list/terminal-list.component';
import {ComboBoxComponent} from '../../../layout/element/combo-box/combo-box.component';
import {TextAreaComponent} from '../../../layout/element/text-area/text-area.component';
import {TerminalLabelComponent} from '../../../layout/element/terminal-label/terminal-label.component';
import {DividerComponent} from '../../../common/divider/divider.component';
import {NgFor, NgIf} from '@angular/common';
import {TextAreaContainerComponent} from "../../../layout/element/text-area-container/text-area-container.component";
import {
  ArdPopnachtTextAreaComponent
} from "../../../layout/element/ard-popnacht-text-area/ard-popnacht-text-area.component";

@Component({
  selector: 'app-document-detail-elements',
  templateUrl: './document-detail-elements.component.html',
  styleUrls: ['./document-detail-elements.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, DividerComponent, TerminalLabelComponent, TextAreaComponent, ComboBoxComponent, TerminalListComponent, TextFieldComponent, DatetimePickerComponent, FileUploadComponent, TextAreaContainerComponent, ArdPopnachtTextAreaComponent]
})
export class DocumentDetailElementsComponent implements OnDestroy {

  @Input() tab!: Tab;
  @Input() documentId?: string;
  //@Input() readonly:boolean = false;

  constructor(public layoutElementUtil: LayoutElementUtil, private screenSizeObserver: ScreenSizeObserverService) {
  }

  public isMobileScreen() {
    return this.screenSizeObserver.isSmallScreen() || this.screenSizeObserver.isXSmallScreen();
  }

  ngOnDestroy(): void {
    this.screenSizeObserver.destroy();
  }

  sectionHasDivider(sectionContainer: Container) {
    const section = this.layoutElementUtil.asSection(sectionContainer);
    return section && section.showDivider;
  }

  getMouseEvent(ws: string[], section: Container, popnacht: Container) {
    /*console.log("GET_MOUSE_EVENT " + ws);
    console.log("GET_MOUSE_EVENT SECTION: " + this.tab.caption);
    console.log("GET_MOUSE_EVENT POPNACHT: " + popnacht.caption); */
    let contents: string = '';
    this.tab.containerList?.forEach(container => {
      if (container.containerType === 'Section') {
        container.containerList?.forEach(c => {
          if (ws.includes(c.containerId) && this.layoutElementUtil.isTextArea(c)) {
            const textArea: TextArea = this.layoutElementUtil.asTextArea(c);
            const popnachtContainer = this.layoutElementUtil.asPopnachtTextArea(popnacht);
            if (textArea.content) {
              if(popnachtContainer.content) {
                popnachtContainer.content = popnachtContainer.content + ' ' + textArea.content;
              } else {
                popnachtContainer.content = textArea.content;
              }
            }
          }
        })
      }
    })
  }
}
