import {Injectable} from '@angular/core';
import {map, of, take, tap} from "rxjs";
import {DmxFileService} from "./http/dmx-file.service";

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  private _cacheUrls: string[] = [];
  private _cachedImages: CachedImage[] = [];

  constructor(private fileService: DmxFileService) {
  }

  getImage(urlPath: string) {
    const index = this._cachedImages.findIndex((image) => image.url === urlPath);
    if (index > -1) {
      console.log('Image found in cache');
      const image = this._cachedImages[index];
      return of(URL.createObjectURL(image.blob));
    }
    return this.fileService.loadRfaLogo(urlPath).pipe(take(1), tap(data => {
      if (data) {
        this.checkAndCacheImages(urlPath, data);
        this.createImageFromBlob(data);
      }
    }), map(data => URL.createObjectURL(data)));
  }

  private createImageFromBlob(blob: Blob) {
    try {
      return URL.createObjectURL(blob);
    } catch (e) {
      console.log(e);
      return URL.createObjectURL(new Blob(['./src/assets/no_logo.png']))
    }
  }

  checkAndCacheImages(urlPath: string, blob: Blob) {
    if (this._cacheUrls.indexOf(urlPath) > -1) {
      this._cachedImages.push({url: urlPath, blob});
    }
  }

  set cacheUrls(urls: string[]) {
    this._cacheUrls = [...this._cacheUrls, ...urls];
  }

  get cacheUrls(): string[] {
    return this._cacheUrls;
  }

  set cachedImages(image: CachedImage) {
    this._cachedImages.push(image);
  }
}

interface CachedImage {
  url: string;
  blob: Blob;

}
