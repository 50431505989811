export enum TextFieldInputType {
  TEXT = 'text',
  DATETIME = 'datetime',
  NUMBER = 'number',
  EMAIL = 'email',
  TEL = 'tel',
  TIME = 'time'
}

export const TEXTFIELD_INPUT_TYPES = Object.values(TextFieldInputType).map(value => value.valueOf());

export enum TextFieldContainerType {
  TEXT = 'textfield',
  NUMBER = 'numbertextfield',
  EMAIL = 'emailtextfield',
  PHONE = 'phonetextfield',
  DATETIME = 'datetimetextfield',
  TEMPERATURE = 'temperaturetextfield',
  TIME = 'timetextfield',
}

export const TEXTFIELD_CONTAINER_TYPES = Object.values(TextFieldContainerType).map(value => value.valueOf());

export const FILTER_STATUS_ALLE = "Alle";

export class StatusCodes {

  PUBLISH: number = 0;
  SAVE: number = 1;
  SCHEDULE: number = 2;
  UNPUBLISHED: number = 3;
}

