import {Injectable} from '@angular/core';
import {BehaviorSubject, take} from "rxjs";
import {Status} from "../model/document.model";
import {DmxDocumentService} from "../service/http/dmx-document.service";
import {BasicStore} from "./basic.store";

@Injectable({
  providedIn: 'root'
})
export class StatusStore extends BasicStore {


  private statusList$ = new BehaviorSubject<Status[]>([]);

  constructor(private dmxDokumentService: DmxDocumentService) {
    super(dmxDokumentService);
    this.init();
  }

  private init(statusFilterCodes?: string[]) {
    this.startLoad();
    this.dmxDokumentService.loadStatusList(statusFilterCodes).pipe(take(1)).subscribe({
      next: (statusList) => {
        this.statusList$.next(statusList);
      },
      error: (err) => {
        this.handleError(err);
      },
      complete: () => {
        this.stopLoad();
      }
    });
  }
  public getStatusList() {
    return this.statusList$.asObservable();
  }
}
