import {Injectable} from '@angular/core';
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {BehaviorSubject, Subject, takeUntil} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeObserverService {

  private _destroyed = new Subject<void>();
  private _currentScreenSize: string | undefined;

  private _onScreenSizeChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  // Create a map to display breakpoint names for demonstration purposes.
  breakpointsMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [Breakpoints.Large, 'Large'],
    [Breakpoints.XLarge, 'XLarge'],
  ]);

  constructor(private breakpointObserver: BreakpointObserver) {
    breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ]).pipe(takeUntil(this._destroyed)).subscribe(result => {
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this._currentScreenSize = this.breakpointsMap.get(query) ?? 'Unknown';
          this._onScreenSizeChange.next(query);
        }
      }
    })
  }


  isXSmallScreen() {
    return this.breakpointObserver.isMatched(Breakpoints.XSmall);
  }

  isSmallScreen() {
    return this.breakpointObserver.isMatched(Breakpoints.Small);
  }

  isMediumScreen() {
    return this.breakpointObserver.isMatched(Breakpoints.Medium);
  }

  isLargeScreen() {
    return this.breakpointObserver.isMatched(Breakpoints.Large);
  }


  isExtraLargeScreen() {
    return this.breakpointObserver.isMatched(Breakpoints.XLarge);
  }

  get currentScreenSize() {
    return this._currentScreenSize;
  }

  get observer() {
    return this.breakpointObserver;
  }


  screenSizeChangeListener() {
    return this._onScreenSizeChange.asObservable();
  }

  destroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }
}
