import {Component} from '@angular/core';
import { ActivatedRoute, RouterLink } from "@angular/router";
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss'],
    standalone: true,
    imports: [MatButtonModule, RouterLink]
})
export class ErrorPageComponent  {

  private readonly PARAM_MSG = 'message';
  private readonly PARAM_ERROR = 'error'
  private readonly PARAM_STATUS_CODE = 'status'

  message: string = 'Fehler beim aufrufen der Seite'
  error: string = 'Unbekannter Fehler'
  status: number = 404;

  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe({
      next: params => {
        this.message = params[this.PARAM_MSG];
        this.status = params[this.PARAM_STATUS_CODE];
        this.error = params[this.PARAM_ERROR];
      }
    })
  }

}
