<div *ngIf="type === 'card'" class="skeleton-card-container">
  <div *ngFor="let i of getArrayFromNumber()" class="skeleton-card pulse">
    <div class="skeleton-card-header shimmer"></div>
    <div class="skeleton-card-body shimmer"></div>
  </div>
</div>

<div *ngIf="type === 'table'" class="skeleton-table-container">

  <div class="skeleton-table">
          <span class="skeleton-table-header">
              <span
                *ngFor="let data of placeHolderData"
                class="skeleton-table-header-item">{{data}}</span>
      </span>
    <div *ngFor="let i of getArrayFromNumber()" class="skeleton-table-item pulse">

      <span *ngFor="let data of placeHolderDataContent" class="skeleton-table-data shimmer">
        <div [style.width]="data.width" class="content shimmer"></div>
      </span>
    </div>
  </div>
</div>
