<div [@fadeIn]="'state'" class="container background">

  <div [class]="'container-message ' + type">
    <span [class]="'container-message-hint-' +type">
      <mat-icon class="message-icon" *ngIf="type === 'warning'">report_problem</mat-icon>
      <mat-icon class="message-icon" *ngIf="type === 'success'">check_circle_outline</mat-icon>
      <mat-icon class="message-icon" *ngIf="type === 'info'">info</mat-icon>
      <mat-icon class="message-icon" *ngIf="type === 'error'">highlight_off</mat-icon>
    </span>{{ message }}

    <div class="container-close-icon">
      <button mat-icon-button (click)="close()">
        <mat-icon class="close-icon">close</mat-icon>
      </button>
    </div>

  </div>

</div>
