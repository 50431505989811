import {Component, Inject} from '@angular/core';
import {DocumentHeader, DocumentModel, SavedDocumentModel, Status} from "../../../../model/document.model";
import {DmxDocumentService} from "../../../../service/http/dmx-document.service";
import {first, map, Observable, take} from "rxjs";
import {StatusCodes} from "../../../../model/constants";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RfaStore} from "../../../../store/rfa/rfa.store";
import {DocumentStore} from "../../../../store/document.store";
import {StatusStore} from "../../../../store/status.store";
import {WorkflowStore} from "../../../../store/workflow.store";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {
  DocumentTableExpansionDetailComponent
} from '../../document-table/document-table-expansion-detail/document-table-expansion-detail.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {DatetimePickerComponent} from '../../../layout/element/datetime-picker/datetime-picker.component';
import {MatButtonModule} from '@angular/material/button';
import {WorkflowPickerComponent} from './workflow-picker/workflow-picker.component';
import {MatStepperModule} from '@angular/material/stepper';
import {LoadingSpinnerComponent} from '../../../common/loading/loading-spinner/loading-spinner.component';
import {AsyncPipe, NgFor, NgIf} from '@angular/common';
import {DividerComponent} from '../../../common/divider/divider.component';
import {MatIconModule} from '@angular/material/icon';
import {STEPPER_GLOBAL_OPTIONS} from "@angular/cdk/stepper";
import {fadeIn} from "../../../../animations/animations";
import {DisplayMessageComponent} from "../../../common/display-message/display-message.component";
import {MatBadgeModule} from "@angular/material/badge";

@Component({
  selector: 'app-document-detail-action',
  templateUrl: './document-detail-action.component.html',
  styleUrls: ['./document-detail-action.component.scss'],
  standalone: true,
  animations: [fadeIn],
  providers: [StatusCodes, {
    provide: STEPPER_GLOBAL_OPTIONS,
    useValue: {displayDefaultIndicatorType: false, showError: true}
  }],
  imports: [MatIconModule, DividerComponent, NgIf, LoadingSpinnerComponent, MatStepperModule, WorkflowPickerComponent, MatButtonModule, DatetimePickerComponent, MatExpansionModule, DocumentTableExpansionDetailComponent, NgFor, AsyncPipe, DisplayMessageComponent, MatBadgeModule]
})
export class DocumentDetailActionComponent {

  //Die Statusliste ist gleich der Buttonbeschreibungen
  statusList$: Observable<Status[]>;
  loading = false;
  saved = false;

  private readonly DETAIL_ACTION_DISMISSAL_TIME_MS = 1000;
  private selectedRfaKuerzel!: string;

  workflowFormGroup = this.formBuilder.group({
    workflowCtrl: new FormControl(this.data.wetterTerminalDocument.header.workflow, Validators.required)
  });

  constructor(private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<DocumentDetailActionComponent>, @Inject(MAT_DIALOG_DATA) public data: {
      wetterTerminalDocument: DocumentModel,
      workflowFilterCodes: string
    },
              private snackBar: MatSnackBar, private restServiceClient: DmxDocumentService, private documentStore: DocumentStore,
              public statusCodes: StatusCodes, private statusStore: StatusStore, private rfaStore: RfaStore, private workflowStore: WorkflowStore) {

    this.dialogRef.backdropClick().pipe(take(1)).subscribe(() => {
      this.dialogRef.close();
    });

    //Holt die Rfa aus dem Datenstore
    this.rfaStore.getSelectedRfa().pipe(first(), map((rundfunkanstalt) => {
      if (rundfunkanstalt)
        this.selectedRfaKuerzel = rundfunkanstalt.kuerzel
    })).subscribe();

    this.statusList$ = this.statusStore.getStatusList().pipe(map((statusList) => this.filterStatusList(statusList)));

    this.workflowStore.storeCodesIfNotExist(this.data.workflowFilterCodes);

    if (data.wetterTerminalDocument.header.workflow) {
      this.workflowFormGroup.controls['workflowCtrl'].setValue(data.wetterTerminalDocument.header.workflow);
    }
  }

  private filterStatusList(statusList: Status[]) {
    const filterCodes: string[] = this.data.wetterTerminalDocument.header.statusFilterCodes.split(',');
    let filteredStatusListe: Status[] = [];
    filterCodes.forEach((statusCode) => {
      statusList.forEach((status) => {
        if (status.code === parseInt(statusCode))
          filteredStatusListe.push(status);
      });
    });
    return filteredStatusListe;
  }

  public publish(status: Status) {
    this.updateHeaderWithStatus(status);
    this.publishDocument();
  }

  private updateHeaderWithStatus(status: Status) {
    if (this.data.wetterTerminalDocument.header) {
      this.data.wetterTerminalDocument.header.status = status;
      this.data.wetterTerminalDocument.header.updated = new Date();
      if (status.code === this.statusCodes.PUBLISH) {
        if (!this.data.wetterTerminalDocument.header.published)
          this.data.wetterTerminalDocument.header.published = this.data.wetterTerminalDocument.header.updated;
        else
          //todo: Das Objekt muss vom Server geholt werden
          this.data.wetterTerminalDocument.header.status = {
            code: this.statusCodes.SCHEDULE,
            kuerzel: 'Vordatieren',
            description: 'Datensatz vordatiert',
            icon: 'hourglass_top'
          }
      }
    }
  }

  /**
   * Schickt das Dokument mit dem gesetzten Status an den REST Service
   * @private
   */
  private publishDocument() {
    this.loading = true;
    this.restServiceClient.publish(this.selectedRfaKuerzel, this.data.wetterTerminalDocument).subscribe({
      next: (response) => {
        this.snackBar.open('Veröffentlichung erfolgreich', 'OK', {
          verticalPosition: 'bottom',
          horizontalPosition: "right"
        });
        const savedDocument = response.body as SavedDocumentModel;
        this.documentStore.addOrUpdateDocument(savedDocument.wetterTerminalDocument.header);

        const unpublishedDocs = savedDocument.unpublished;
        if (unpublishedDocs && unpublishedDocs.length > 0) {
          this.documentStore.setDocumentsUnpublished(unpublishedDocs);
        }

        this.delayedDismiss(savedDocument.wetterTerminalDocument.header);
        [this.loading, this.saved] = [false, true];
      },
      error: err => {
        this.snackBar.open(`Fehler beim speichern aufgetreten! ${err} `, 'OK');
        console.log('ERROR:', err);
        [this.loading, this.saved] = [false, false];
      }
    })
  }

  private delayedDismiss(documentHeader: DocumentHeader) {
    //Warte 1 sec. bevor das Fenster schließt
    setTimeout(() => {
      this.dialogRef.close(documentHeader);
    }, this.DETAIL_ACTION_DISMISSAL_TIME_MS)
  }

  notPublishedYet() {
    return !this.data.wetterTerminalDocument.header.status || this.data.wetterTerminalDocument.header.status?.code === this.statusCodes.SAVE || this.data.wetterTerminalDocument.header.status?.code === this.statusCodes.SCHEDULE;
  }
}
