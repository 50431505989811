import {animate, animateChild, group, query, state, style, transition, trigger} from "@angular/animations";

export const slideToCenter =
  trigger('slideToCenter', [
    state('closed', style({
    })),
    state('open', style({
      transform: 'translateX(100%)'
    })),
    transition('* => *', animate(250))
  ]);
export const fadeIn =
  trigger('fadeIn', [
    transition('void => *', [
      style({opacity: 0}),
      animate(500, style({opacity: 1}))
    ])
  ]);

export const fadeOut =
  trigger('fadeOut', [
    transition('void => *', [
      style({opacity: 1}),
      animate(500, style({opacity: 0}))
    ])
  ]);

export const slideInAnimation =
  trigger('routeAnimations', [
    transition('LandingPage <=> DocumentTablePage', [
      style({position: 'relative'}),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({left: '-100%'})
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('500ms ease-out', style({left: '100%'}))
        ]),
        query(':enter', [
          animate('500ms ease-out', style({left: '0%'}))
        ]),
      ]),
    ]),
    transition('* <=> *', [
      style({position: 'relative'}),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({left: '-100%'})
      ]),
      query(':leave', animateChild(),{optional: true}),
      group([
        query(':leave', [
          animate('500ms ease-out', style({left: '100%', opacity: 0}))
        ],{optional: true}),
        query(':enter', [
          animate('500ms ease-out', style({left: '0%'}))
        ]),
        query('@*', animateChild(),{optional: true})
      ]),
    ])
  ]);
