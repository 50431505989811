@if (wetterTerminalDocumentWrapper && wetterTerminalDocumentWrapper.data$ | async; as wetterTerminalDoc) {
  <div class="document-detail-container">
    <mat-tab-group mat-stretch-tabs="false"
                   mat-align-tabs="start"
                   class="document-detail-tab-group"
                   animationDuration="500ms"
                   [(selectedIndex)]="selectedTabIndex">

      @for (tab of wetterTerminalDoc?.tabs; track $index) {
        <ng-container [ngTemplateOutlet]="recursiveTabTmpl"
                      [ngTemplateOutletContext]="{$implicit: tab}"
                      class="document-detail-tab">

          <mat-tab [label]="tab!.caption">
            <app-document-detail-elements [documentId]="documentId"
                                          *ngIf="tab.containerList && tab.containerList[0].containerType !== 'Tab'; else tabgroup"
                                          [tab]="tab">
            </app-document-detail-elements>
            <app-document-detail-elements [documentId]="documentId"
                                          *ngIf="tab.containerList && getIndexOfNonTabElement(tab) > 0 && tab.containerList[getIndexOfNonTabElement(tab)]"
                                          [tab]="tab">
            </app-document-detail-elements>

          </mat-tab>

          <ng-template #tabgroup>
            <mat-tab-group animationDuration="500ms" mat-stretch-tabs="true" mat-align-tabs=""
                           *ngIf="selectedTabIndex > 0" selectedIndex="0">
              @for (subTab of tab.containerList; track $index) {
                <mat-tab [label]="subTab!.caption">
                  <app-document-detail-elements [documentId]="documentId"
                                                [tab]="layoutElementUtil.asTab(subTab)">
                  </app-document-detail-elements>
                </mat-tab>
              }
            </mat-tab-group>
          </ng-template>
        </ng-container>
      }
    </mat-tab-group>

    <!-- Tabs. Mit dem recursiveTabTmpl werden rekursiv subtabs rausgerendert -->
    <ng-template #recursiveTabTmpl let-tab>
      <ng-container *ngFor=" let subTab of tab.containerList"
                    [ngTemplateOutlet]="recursiveTabTmpl"
                    [ngTemplateOutletContext]="{$implicit: subTab}">
      </ng-container>
    </ng-template>
  </div>
} @else {
  <app-loading-error [loadingWrapper]="wetterTerminalDocumentWrapper"
                     [message]="'Fehler beim Laden des Dokuments: ' + documentId"
                     (onReload)="reload()">
  </app-loading-error>
}
