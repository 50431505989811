import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TEXTFIELD_INPUT_TYPES, TextFieldInputType} from "../../../../model/constants";
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {TemperatureTextField} from "../../../../model/layout.model";
import {LayoutElementUtil} from "../../../../service/layout/layout-element.service";

@Component({
    selector: 'app-temperature-text-field',
    templateUrl: './temperature-text-field.component.html',
    styleUrls: ['./temperature-text-field.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule]
})
export class TemperatureTextFieldComponent implements OnInit, OnDestroy {

    @Input() temperatureTextField!: TemperatureTextField;
    inputType: string = TextFieldInputType.TEXT;

    errorMessage;


    constructor(public layoutElemeneUtil: LayoutElementUtil) {
        this.errorMessage = 'Fehlerhafte Eingabe';
    }

    ngOnDestroy() {

    }

    ngOnInit(): void {
        // @ts-ignore
        this.inputType = TEXTFIELD_INPUT_TYPES.find(type => type === this.temperatureTextField?.fieldType);
    }

    onKeyPress($event: any) {
        if (this.inputType === TextFieldInputType.TEXT && this.temperatureTextField.maxChars !== null && this.temperatureTextField.maxChars !== undefined && this.temperatureTextField.maxChars > 0) {
            const contentString = this.temperatureTextField.content.toString();
            const isNegative = contentString.startsWith('-');
            const trimmedContent = isNegative
                ? '-' + contentString.slice(1, this.temperatureTextField.maxChars)
                : contentString.slice(0, this.temperatureTextField.maxChars);
            this.temperatureTextField.content = parseInt(trimmedContent, 10);
        }
    }

    onInputChange($event: any) {
            const parsedValue = parseInt($event, 10);
            if (!isNaN(parsedValue)) {
                this.temperatureTextField.content = parsedValue;
            } else {
                this.temperatureTextField.content = '';
            }
        }

}
