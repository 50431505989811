import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {FileInfo} from "../../model/document.model";

@Injectable({
  providedIn: 'root'
})
export class DmxFileService {

  private readonly apiUrl = `${environment.terminalApiUrl}/files`

  constructor(private httpClient: HttpClient) {
  }

  listFiles(rfaKuerzel: string, thema: string): Observable<FileInfo[]> {

    return this.httpClient.get(`${this.apiUrl}/${rfaKuerzel}`, {
      params: new HttpParams().set('thema', thema),
    }).pipe(map((data) => data as FileInfo[]));
  }

  upload(rfa: string, files: File[], thema?: string | null): Observable<FileInfo[]> {
    let data: FormData = new FormData();
    files.forEach(file => data.append('files', file));

    console.log(data);
    let httpParams;
    if (thema) {
      httpParams = new HttpParams().set('thema', thema);
    }
    return this.httpClient.post(`${this.apiUrl}/${rfa}`, data, {
      params: httpParams,
      reportProgress: true
    }).pipe(map(data => data as FileInfo[]));
  }


  download(rfa: string, thema: string, fileName: string, persistTime: string) {
    return this.httpClient.get(`${this.apiUrl}/${rfa}`, {
      params: this.createLoadParams(thema, fileName, persistTime),
      headers: new HttpHeaders().set('Accept', 'application/octet-stream'),
      responseType: 'blob' as 'json'
    });
  }

  delete(file: FileInfo, rfa: string) {
    return this.httpClient.delete(`${this.apiUrl}/${rfa}`, {
      params: this.createLoadParams(file.thema, file.fileName, file.persistTime),
      responseType: 'text'
    });
  }

  private createLoadParams(thema: string, fileName: string, persistTime: string) {
    return new HttpParams().append('thema', thema).append('fileName', fileName).append('persistTime', persistTime);

  }

  loadRfaLogo(rfa: string) {
    return this.httpClient.get(`${this.apiUrl}/${rfa}/logo`, {
      responseType: "blob"
    }).pipe(map(data => data as Blob));
  }
}
