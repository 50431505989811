import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {BehaviorSubject, Observable, Subject, take} from "rxjs";
import {environment} from "../../../environments/environment";
import {getMessaging, getToken} from "firebase/messaging";
import firebase from "firebase/compat";

@Injectable({
  providedIn: 'root'
})
export class FirebaseCloudMessagingService {

  private readonly apiUrl: string = environment.messagingApiUrl
  private httpHeader = new HttpHeaders();
  private messageSubject: Subject<any>;

  constructor(private httpClient: HttpClient) {
    this.httpHeader.set('Content-Type', 'application/json');
    this.messageSubject = new BehaviorSubject<any>(null);
  }

  public requestPermission() {
    const messaging = getMessaging();
    getToken(messaging, {vapidKey: environment.firebaseConfig.vapidKey})
      .then((currentToken) => {
        if (currentToken) {
          //Nun muss der Token zum Server, der dann explizit damit eine Nachricht senden kann!
          this.uploadToken({token: currentToken})
            .pipe(take(1)).subscribe({
            next: value => console.log('Token erfolgreich auf den Server geladen', value),
            error: err => console.error('Fehler beim Upload des Tokens', err)
          })
          console.log('Permission granted! Token received', currentToken)
        } else {
          console.log('No registration token available. Request permission to generate one.');
          //TODO: Hier muss eine Benachrichtigung hin
        }
      }).catch((err) => console.error('An error occurred while retrieving token. ', err));
  }

  public listen() {
    //Das ist der Service-Worker Background listener
    //Allerdings wird der auch aufgerufen, wenn im Vordergrund (doppelter Aufruf)
    navigator.serviceWorker.onmessage = (event) => {
      console.log('Message received from background sw', event.data);
      this.messageSubject.next(event.data);
    }
  }
  asObservable(): Observable<any> {
    return this.messageSubject.asObservable();
  }

  private uploadToken(token: { token: string }) {
    return this.httpClient.post(`${this.apiUrl}/token`, token, {headers: this.httpHeader})
  }
}
