export const gatewayUrl = "https://dmx4-api-gateway-test.gcp.cloud.hr.de"

export const environment = {
  production: false,
  enableAuth: true,
  terminalApiUrl: `${gatewayUrl}/api/wetter/terminal`,
  messagingApiUrl: `${gatewayUrl}/api/wetter/messaging/terminal`,
  auth: {
    clientId: 'fb20fde3-1fa0-43c7-8e9c-fca7e7b29073', // Application (client) ID from the app registration
    authority: 'https://login.microsoftonline.com/daaae532-0750-4b35-8f3f-fdd6ba4c86f0', // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
    redirectUri: 'https://wetter-terminal-test.gcp.cloud.hr.de',
    scope: ['api://3cb3e179-b17f-4ffe-a3ce-1c0127597a18/access_as_user']
  },
  firebaseConfig: {
    apiKey: "AIzaSyB5_eXHVXhDGGx7fYmzOYiuRo-REgwjx_w",
    authDomain: "wetter-dts-test-4187936002.firebaseapp.com",
    databaseURL: "https://wetter-dts-test-4187936002.firebaseio.com",
    projectId: "wetter-dts-test-4187936002",
    storageBucket: "wetter-dts-test-4187936002.appspot.com",
    messagingSenderId: "563283248772",
    appId: "1:563283248772:web:016a9c5aa4223a4d1ee4d1",
    vapidKey: "BKVXZZlPWTJTWbY2NBdvSC7rFdVWGE40-EJzWw_PSj7Gqlml44XeNqJK5_OjjLIP8B0J6P5a4ovINsTUqdeE3fk"
  },
};
