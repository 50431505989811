<app-document-table-action-header (onApplyFilter)="filterTable($event)"></app-document-table-action-header>

@if (loading$ | async; as loading) {
  <app-loading-spinner *ngIf="loadingTime <= MAX_LOAD_TIME"></app-loading-spinner>
  <app-loading-skeleton *ngIf="loadingTime > MAX_LOAD_TIME" [type]="'table'"
                        [count]="2"></app-loading-skeleton>

  <app-display-message *ngIf="error$ | async as err" [type]="'error'" [message]="err"
                       [visible]="true"></app-display-message>

} @else {
  @if (dataSource) {
    @defer () {
      <table [dataSource]="dataSource"
             (matSortChange)="sortData($event)"
             mat-table multiTemplateDataRows class="mat-elevation-z1"
             matSort matSortActive="id" matSortDirection="desc">
        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
          <td mat-cell *matCellDef="let row">{{ selectedRfaKuerzel.toUpperCase() }}_{{ row.documentId }}
          </td>
        </ng-container>

        <!-- Veröffentlichung Column -->
        <ng-container matColumnDef="published">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Beginn Veröff.</th>
          <td style="padding: 10px" mat-cell *matCellDef="let row">
          <span *ngIf="row.published" class="table-data-row"
                [class.scheduled]="row.status.code === statusCodes.SCHEDULE">
            {{ row.published | date: dateFormat }}
          </span>
            <span *ngIf="!row.published">-</span>
          </td>
        </ng-container>

        <!-- Veröffentlichung Column -->
        <ng-container matColumnDef="unpublished">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Ende Veröff.</th>
          <td mat-cell *matCellDef="let row">
      <span *ngIf="row.unpublished"
            class="table-data-row">
        {{ row.unpublished | date: dateFormat }}
      </span>
            <span *ngIf="!row.unpublished">-</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="updated">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Letzte Änderung</th>
          <td mat-cell *matCellDef="let row">{{ row.updated | date: dateFormat }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td matTooltip="{{row.status.description}}" matTooltipPosition="left" mat-cell *matCellDef="let row">
            <mat-icon
              [class.unpublished]="row.unpublished !== null && row.unpublished !== undefined">{{ row.status.icon }}
            </mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="workflow">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Workflow</th>
          <td matTooltip="{{row.workflow.description}}" matTooltipPosition="left" mat-cell *matCellDef="let row">
            <mat-icon>{{ row.workflow.icon }}</mat-icon>
          </td>
        </ng-container>

        <!-- Bearbeiten -->
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Bearbeiten</th>
          <td mat-cell *matCellDef="let row">

            <!-- Übernehme vollständigen Datensatz in NEUES Dokument -->
            <button matTooltip="Betrachten" color="primary"
                    *ngIf="row?.status?.code === statusCodes.PUBLISH || row?.status?.code === statusCodes.UNPUBLISHED"
                    routerLink="details"
                    (click)="setReadonlyModeTrue()"
                    [queryParams]="{documentId: row?.documentId}" mat-icon-button>
              <mat-icon>visibility</mat-icon>
            </button>
            <button matTooltip="Weiter bearbeiten" color="primary"
                    *ngIf="row?.status?.code === statusCodes.SCHEDULE || row?.status?.code === statusCodes.SAVE"
                    routerLink="details"
                    (click)="setReadonlyModeFalse()"
                    [queryParams]="{documentId: row?.documentId}" mat-icon-button>
              <mat-icon>edit</mat-icon>
            </button>
            <button matTooltip="Daten übernehmen" color="primary" *ngIf="row?.status?.code !== statusCodes.SAVE"
                    routerLink="details"
                    (click)="setReadonlyModeFalse()"
                    [queryParams]="{documentId: row?.documentId, copyProfile: 'ALL'}" mat-icon-button>
              <mat-icon>post_add</mat-icon>
            </button>


          </td>
        </ng-container>

        <!-- Dreipunktemenü -->
        <ng-container matColumnDef="more">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Mehr</th>
          <td mat-cell *matCellDef="let row">
            <app-document-table-menu (click)="loadingTime = 0" [documentHeader]="row">
            </app-document-table-menu>
          </td>
        </ng-container>


        <!-- Hier beginnt das Expansion Panel, wird nur bei Mobil angezeigt -->
        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button
                    (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
              <mat-icon style="color: black"
                        *ngIf="expandedElement !== element">keyboard_arrow_down
              </mat-icon>
              <mat-icon style="color: black" *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div
              [class]="element == expandedElement ? 'expanded-element-detail expanded' : 'expanded-element-detail collapsed'">
              <app-document-table-expansion-detail [documentHeader]="element"></app-document-table-expansion-detail>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            (click)="this.selectedDocument = row" [class.selected-document]="selectedDocument === row"
            class="element-row" [class.expanded-row]="expandedElement === row"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="element-detail-row"></tr>
      </table>
    }
  }
}

<mat-paginator [length]="dataSource.data.length" [pageSize]="10"
               [pageSizeOptions]="[10,20,50]"
               aria-label="Wähle Seite der Resultate"></mat-paginator>
