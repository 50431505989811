import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CharacterCounterComponent} from "../character-counter/character-counter.component";
import {FormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NgIf} from "@angular/common";
import {TextAreaComponent} from "../text-area/text-area.component";

@Component({
  selector: 'app-ard-popnacht-text-area',
  standalone: true,
    imports: [
        CharacterCounterComponent,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        NgIf
    ],
  templateUrl: './ard-popnacht-text-area.component.html',
  styleUrl: './ard-popnacht-text-area.component.css'
})
export class ArdPopnachtTextAreaComponent extends TextAreaComponent {
  @Input() copyFromIds: string[] | undefined;
  @Output() copyEvent = new EventEmitter<string[]>();


  fireCopyEvent($event: MouseEvent) {
    //console.log("FIRE_COPYEVENT :" + $event.target);
    if(this.copyFromIds) {
      //console.log("FIRE_COPYEVENT COPY_IDs: " + this.copyFromIds.length);
      this.copyEvent.emit(this.copyFromIds);
    }
  }
}
