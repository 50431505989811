import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {map, Observable, of, tap} from "rxjs";
import {Workflow} from "../../../../../model/document.model";
import {WorkflowStore} from "../../../../../store/workflow.store";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatChipsModule} from '@angular/material/chips';
import {AsyncPipe, NgFor, NgIf} from '@angular/common';

@Component({
  selector: 'app-workflow-picker',
  templateUrl: './workflow-picker.component.html',
  styleUrls: ['./workflow-picker.component.scss'],
  standalone: true,
  imports: [NgIf, MatChipsModule, FormsModule, ReactiveFormsModule, NgFor, MatFormFieldModule, AsyncPipe]
})
export class WorkflowPickerComponent implements OnInit {

  private readonly workflows$: Observable<Workflow[]>;
  workflowsWithFilter$: Observable<Workflow[]> = of([]);

  @Input() control?: FormControl;
  @Input() disabled: boolean = false;
  @Input() workflowFilterCodes?: string;
  @Input() selectedWorkflow?: Workflow = undefined;
  @Output() selectedWorkflowChange: EventEmitter<Workflow> = new EventEmitter<Workflow>();

  constructor(private workflowStore: WorkflowStore) {
    this.workflows$ = this.workflowStore.getWorkflows();
  }

  ngOnInit() {
    setTimeout(() => {
      this.workflowsWithFilter$ = this.workflows$.pipe(
        tap((workflows) => {
          this.selectedWorkflow = workflows.find(w => w.code == this.selectedWorkflow?.code);
          console.log("WorkflowPickerComponent.ngOnInit() selectedWorkflow: " + this.selectedWorkflow?.kuerzel);
          if (this.disabled) this.control?.disable();
        }), map(workflows => this.workflowFilterCodes ? this.workflowStore.filterWorkflows(this.workflowFilterCodes) : workflows));
    }, 300);
  }

  onWorkflowChange(workflow: Workflow) {
    this.selectedWorkflowChange.emit(workflow);
  }
}
