<h3>{{ terminalList?.caption }}</h3>
@for (lists of terminalList?.containerList; track $index) {
  <div class="terminal-list-row" id="terminal-list-row"
       [style]="lists.inlineStyle ? layoutElementUtil.translateStyle(lists.inlineStyle) : ''">
    @for (group of lists?.containerList; track $index) {
      <div class="terminal-list-row-group"
           [style]="group.inlineStyle ? layoutElementUtil.translateStyle(group.inlineStyle) : ''">
        <div class="terminal-list-row-group-caption">{{ group.caption }}</div>
        <div [style]="row.inlineStyle ? layoutElementUtil.translateStyle(row.inlineStyle) : ''"
             class="terminal-list-row-item"
             *ngFor="let row of group.containerList">
          <div *ngIf="layoutElementUtil.isTemperatureTextField(row); else textField" class="terminal-list-row-item-input">
            <app-temperature-text-field [temperatureTextField]="layoutElementUtil.asTemperatureTextField(row)"></app-temperature-text-field>
          </div>
          <ng-template #textField>
            <div class="terminal-list-row-item-input" *ngIf="layoutElementUtil.isTextField(row)">
              <app-text-field [textField]="layoutElementUtil.asTextField(row)"></app-text-field>
            </div>
          </ng-template>
          <div *ngIf="layoutElementUtil.isComboBox(row)" class="terminal-list-row-item-input">
            <app-combo-box
              (copyEvent)="layoutElementUtil.copyComboBoxItemForAllInList($event,terminalList,row.containerId)"
              [comboBox]="layoutElementUtil.asComboBox(row)"></app-combo-box>
          </div>
          <div *ngIf="layoutElementUtil.isTerminalLabel(row)" class="terminal-list-row-item-input">
            <app-terminal-label [label]="row"></app-terminal-label>
          </div>
        </div>
      </div>
    }
  </div>
}

