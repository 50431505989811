import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private showActions$ = new EventEmitter<{ show: boolean }>();
  private refresh$ = new EventEmitter<{ refresh: boolean }>();

  constructor() {
  }

  public refresh = () => this.refresh$.emit({refresh: true});
  public getRefresh = () => this.refresh$.asObservable();
  public showPublishActions = (show: boolean) => this.showActions$.emit({show});
  public getShowPublishActions = () => this.showActions$.asObservable();
}
