import {Injectable} from "@angular/core";
import {BasicStore} from "./basic.store";
import {DmxDocumentService} from "../service/http/dmx-document.service";
import {BehaviorSubject, retry, take} from "rxjs";
import {Workflow} from "../model/document.model";
import * as repl from "repl";

@Injectable({
  providedIn: 'root'
})
export class WorkflowStore extends BasicStore {

  private workflow$ = new BehaviorSubject<Workflow[]>([]);

  private workflowUrlFilterCodes: string[] = [];

  constructor(private dmxDokumentService: DmxDocumentService) {
    super(dmxDokumentService);
    console.log('INFO: WorkflowStore created');
  }

  private init(filterCodes: string[]) {
    this.startLoad();
    console.log('INFO: WorkflowStore.init() - codes: ', filterCodes);
    this.dmxDokumentService.loadWorkflowList(filterCodes).pipe(take(1)).subscribe({
      next: data => {
        if (this.workflow$.getValue().length === 0) {
          this.workflow$.next(data);
        } else {
          this.workflow$.next([...this.workflow$.getValue(), ...data]);
        }
      },
      error: err => this.handleError(err),
      complete: () => this.stopLoad()
    });
  }

  public getWorkflows() {
    return this.workflow$.asObservable();
  }

  storeCodesIfNotExist(filterCodesQuery: string) {
    const codes = this.extractCodesFromUrl(filterCodesQuery);
    let codesToQuery: string[] = [];
    codes.forEach(code => {
      if (!this.workflowUrlFilterCodes.includes(code)) {
        this.workflowUrlFilterCodes.push(code);
        codesToQuery.push(code);
      }
    });

    if (codesToQuery.length > 0)
      this.init(codesToQuery);
  }

  filterWorkflows(filterCodesQuery: string) {
    const filterCodes = this.extractCodesFromUrl(filterCodesQuery);
    const workflows = this.workflow$.getValue();
    return workflows.filter(wf => filterCodes.includes(wf.code.toString()));
  }

  private extractCodesFromUrl(filterCodesQuery: string) {
    return filterCodesQuery ? filterCodesQuery.split(',') : [];
  }
}
