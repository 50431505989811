import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {Status} from "../../../../../model/document.model";
import {FILTER_STATUS_ALLE} from "../../../../../model/constants";
import {ScreenSizeObserverService} from "../../../../../service/layout/screen-size-observer.service";
import { FormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-status-filter',
    templateUrl: './status-filter.component.html',
    styleUrls: ['./status-filter.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatChipsModule,
        FormsModule,
        NgFor,
    ],
})
export class StatusFilterComponent implements OnDestroy {

  //Hier wird nach Status gefiltert. Der FILTER_STATUS_ALLE gibt es nur Clientseitig und lediglich für die Ansicht
  @Input() statusList: Status[] = [];
  @Output() filterChange: EventEmitter<string> = new EventEmitter<string>();

  selectedStatus: string = FILTER_STATUS_ALLE;
  statusAlle: string = FILTER_STATUS_ALLE;
  showFilter: boolean = false;

  constructor(private screenSizeObserver: ScreenSizeObserverService) {
  }

  applyFilter() {
    this.filterChange.emit(this.selectedStatus);
  }

  isMobileScreen() {
    return this.screenSizeObserver.isXSmallScreen();
  }

  ngOnDestroy() {
    this.screenSizeObserver.destroy();
  }
}
