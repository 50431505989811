<div class="document-detail-workflow-toggle-button-group-container">
  <mat-chip-listbox #listbox *ngIf="control" class="document-detail-workflow-toggle-button-group"
                    [value]="selectedWorkflow"
                    [formControl]="control" required>
    <ng-container *ngIf="workflowsWithFilter$ | async as workflows">
      <mat-chip-option class="document-detail-workflow-toggle-button"
                       (click)="onWorkflowChange(workflow)"
                       *ngFor="let workflow of workflows"
                       [value]="workflow">
        {{ workflow.kuerzel }}
      </mat-chip-option>
    </ng-container>
  </mat-chip-listbox>

</div>
<mat-error *ngIf="!selectedWorkflow">Bitte wählen Sie einen Workflow aus!
</mat-error>

