@if (isMobileScreen()) {
  <button class="document-table-action-filter-btn" mat-icon-button
          [matMenuTriggerFor]="filtermenu"
          (click)="showFilter ? showFilter = false : showFilter = true;">
    <mat-icon color="primary">filter_alt</mat-icon>
  </button>
  <span class="selected-status-text">{{ selectedStatus }}</span>
} @else {
  <div *ngIf="!isMobileScreen(); then filterListBox" class="document-table-action-filter">
  </div>
}
<mat-menu #filtermenu="matMenu">
  <div *ngIf="showFilter; then filterListBox"></div>
</mat-menu>

<ng-template #filterListBox>
  <mat-chip-listbox (ngModelChange)="selectedStatus != $event ? selectedStatus = $event : null;applyFilter()"
                    aria-label="Statusfilter auswahl"
                    [(ngModel)]="selectedStatus"
                    class="document-table-action-filter-list">
    <mat-chip-option [class.selected]="selectedStatus === statusAlle" class="document-table-action-filter-chip"
                     [value]="statusAlle">{{ statusAlle }}
    </mat-chip-option>
    @for (status of statusList; track status.code) {
      <mat-chip-option [class.selected]="selectedStatus === status.kuerzel" class="document-table-action-filter-chip"
                       [value]="status.kuerzel">
        {{ status.filterText }}
      </mat-chip-option>
    }
  </mat-chip-listbox>
</ng-template>
