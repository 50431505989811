import {Component, Input} from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-character-counter',
    templateUrl: './character-counter.component.html',
    styleUrls: ['./character-counter.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class CharacterCounterComponent {

  @Input() maxChars: number = 500;
  @Input() maxCharsWarning: number = 500;
  @Input() charSequenceLength: number = 0;

  @Input() position: 'inside' | 'below' = 'inside';

  constructor() {
  }
}
