import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DatePipe, NgIf} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NgxMatDatetimePickerModule} from "@angular-material-components/datetime-picker";
import {LayoutElementUtil} from "../../../../service/layout/layout-element.service";

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule, MatIconModule, NgIf, NgxMatDatetimePickerModule]
})
export class DatetimePickerComponent implements OnInit {

  //TODO: Die Library @angular-material-components/datetime-picker unterstützt offiziell noch nicht Angular 17, deshalb gibt es beim npm install ein paar Probleme mit dependencies
  // TODO: Wenn es eine aktuelle Version des pickers für Angular 17 gibt, dann die installieren / https://www.npmjs.com/package/@angular-material-components/datetime-picker
  @Output() dateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Input() showClearButton = false;
  @Input() disabled: boolean = false;
  @Input() date?: Date;
  @Input() showDefaultTime?: boolean;

  constructor(private datePipe: DatePipe, protected layoutElemeneUtil: LayoutElementUtil) {
  }

/*  ngOnInit(): void {
    /!*
    * Wenn showDefaultTime gesetzt ist, dann wird das aktuelle Datum und die aktuelle Uhrzeit gesetzt
    * Die Minuten werden auf die nächsten 5 Minuten aufgerundet
     *!/
    if (this.date === undefined || this.date === null || this.date.toString().length === 0) {
      this.date = new Date();
      this.date.setMinutes(Math.ceil(this.date.getMinutes() / 5) * 5);
      if (this.showDefaultTime)
        this.dateChange.emit(this.date);
    } else {
      this.date = new Date(this.date);
    }

    // Wenn ein Datum gesetzt ist, dann wird dieses Datum in das Format dd.MM.yyyy HH:mm mit UTC+2 umgewandelt
    // So wird das Datum im Datetimepicker korrekt angezeigt
    let transformed = this.transformDate();
    if (transformed) this.date = new Date(transformed);
  }*/

  ngOnInit(): void {
    // Initially, the date is not set
    this.date = undefined;
    if(this.layoutElemeneUtil.ReadonlyMode) {
      this.disabled = true;
    }
  }

  onDatePickerClick(): void {
    if (!this.date) {
      this.date = new Date();
      this.date.setMinutes(Math.ceil(this.date.getMinutes() / 5) * 5); // TODO: wenn ==, dann auf die nächsten 5 minuten erhöhen
      //if (this.showDefaultTime) {
        this.dateChange.emit(this.date);
      //}
    } else {
      this.dateChange.emit(this.date);
    }
  }

  applyDateChange = () => this.dateChange.emit(this.date);

  private transformDate() {
    return this.datePipe.transform(this.date, 'dd.MM.yyyy HH:mm', 'UTC+2', 'de-DE');
  }

  transformUTCDateToLocalDate(): void {
    if (this.date) {
      let d: Date = new Date();
      let seconds = this.date.getSeconds();
      let minutes = this.date.getMinutes();
      let hours = this.date.getHours();
      let day = this.date.getDate();
      let month = this.date.getMonth();
      let year = this.date.getFullYear();

      d.setUTCSeconds(seconds);
      d.setUTCMinutes(minutes);
      d.setUTCHours(hours);
      d.setUTCFullYear(year, month, day);

      this.date = d;
    }
  }

  onChange($event: Event) {
    // Get the new input value
    const newValue = ($event.target as HTMLInputElement).value;

    if(this.isValidDate(newValue)) {
      if (!this.date) {
        this.date = new Date();
      }
      //todo: parsen 1. nach komma, 2. nach punkt in [0] und doppelpunk in [1]
      const splitted = newValue.split(",");
      const datum = splitted[0];
      const time = splitted[1];
      const splittedDatum = datum.split(".");
      const splittedTime = time.split(":");
      const dayOfMonth = splittedDatum[0].trim();
      const month = splittedDatum[1].trim();
      const year = splittedDatum[2].trim();
      const hour = splittedTime[0].trim();
      const minutes = splittedTime[1].trim();

      this.date?.setDate(parseInt(dayOfMonth));
      this.date?.setMonth(parseInt(month)-1);
      this.date?.setFullYear(parseInt(year));
      this.date?.setHours(parseInt(hour));
      this.date?.setMinutes(parseInt(minutes));
      console.log("NEW DATE AFTER: " + this.date);
      this.dateChange.emit(this.date);

    } else {
      console.log('Input value changed but null, empty or not valid:');
    }
  }

  isValidDate(dateValue:string): boolean {
    let regex = new RegExp("^([1-9]|([012][0-9])|(3[01])).([1-9]|1[012]).\\d\\d\\d\\d, [012]{1}[0-9]:[0-6][0-9]$");
    //let regex = new RegExp("^([1-9]|([012][0-9])|(3[01])).([0]{1}[1-9]|1[012]).\\d\\d\\d\\d, [012]{1}[0-9]:[0-6][0-9]$");
    let match:RegExpExecArray | null = regex.exec(dateValue);

    return match != null && match && match.length > 0;
    }
}
