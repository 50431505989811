import {HttpHeaders} from "@angular/common/http";

export class FileUtil {

  public static downloadFile(data: any, filename: string, type?: string) {
    const blob = new Blob([data], {type: type ? type : 'text/plain;charset=UTF-8'});
    const url = window.URL.createObjectURL(blob);
    this.createDownloadAnchor(url, filename);

    window.open(url);
  }

  public static extractFileNameFromHeader(headers: HttpHeaders) {
    let filename = headers.get('Content-Disposition')?.trim().split('filename=')[1];
    return filename ? filename : 'no_filename.txt';
  }

  private static createDownloadAnchor(fileUrl: string, fileName: string) {
    const downloadAnchor = document.createElement('a');
    downloadAnchor.style.display = 'none';

    downloadAnchor.href = fileUrl;
    downloadAnchor.download = fileName;
    downloadAnchor.text = fileName
    downloadAnchor.click();
    downloadAnchor.remove();
  }
}
