<mat-form-field [style]="temperatureTextField.inlineStyle" floatLabel="auto" class="textbox"
                appearance="outline">
    <mat-label>{{ temperatureTextField.caption }}</mat-label>
    <input class="textfield-input"
           [placeholder]="temperatureTextField.caption"
           [type]="inputType"
           [max]="100"
           [(ngModel)]="temperatureTextField.content"
           (ngModelChange)="onInputChange($event)"
           (keydown)="onKeyPress($event)"
           [disabled]="layoutElemeneUtil.ReadonlyMode"
           matInput/>
    <span class="suffix" matSuffix>{{temperatureTextField.fieldSuffix}}</span>
    <span class="prefix" matPrefix>{{temperatureTextField.fieldPrefix}}</span>
</mat-form-field>


