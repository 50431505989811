import {Component, Input} from '@angular/core';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-loading-skeleton',
    templateUrl: './loading-skeleton.component.html',
    styleUrls: ['./loading-skeleton.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor]
})
export class LoadingSkeletonComponent {

  @Input() type: 'card' | 'table' = 'card'
  @Input() count: number = 1;

  placeHolderData = ['ID', 'Beginn Veröff.', 'Ende Veröff.', 'Status', 'Workflow', 'Bearbeiten', 'Mehr'];
  placeHolderDataContent = [{width: '120px'}, {width: '50px'}, {width: '50px'}, {width: '70px'}, {width: '70px'}, {width: '30px'}, {width: '50px'}];

  public getArrayFromNumber() {
    return new Array(this.count);
  }

}
