import {Component, EventEmitter, Output} from '@angular/core';
import {Observable} from "rxjs";
import {EventService} from "../../../../service/event.service";
import {Status} from "../../../../model/document.model";
import {StatusStore} from "../../../../store/status.store";
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { StatusFilterComponent } from './status-filter/status-filter.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { LoadingSpinnerComponent } from '../../../common/loading/loading-spinner/loading-spinner.component';

@Component({
    selector: 'app-document-table-action-header',
    templateUrl: './document-table-action-header.component.html',
    styleUrls: ['./document-table-action-header.component.scss'],
    standalone: true,
    imports: [
        LoadingSpinnerComponent,
        NgIf,
        StatusFilterComponent,
        MatButtonModule,
        RouterLink,
        MatRippleModule,
        MatIconModule,
        AsyncPipe,
    ],
})
export class DocumentTableActionHeaderComponent {
  //Callback/EventEmitter, sobald ein FilterEvent getriggert wird
  @Output() private onApplyFilter: EventEmitter<string> = new EventEmitter<string>();

  statusList$: Observable<Status[]>;
  loading$: Observable<boolean>;
  error$: Observable<string | null>;

  constructor(private statusStore: StatusStore, private eventService: EventService) {
    this.loading$ = this.statusStore.isLoading();
    this.error$ = this.statusStore.getError();
    this.statusList$ = this.statusStore.getStatusList();
  }

  applyFilter(filterStatusText: string) {
    this.onApplyFilter.emit(filterStatusText);
  }

  refresh() {
    this.eventService.refresh();
  }
}
