import {Injectable} from '@angular/core';
import {BasicStore} from "./basic.store";
import {ComboBoxItem, DocumentModel} from "../model/document.model";
import {BehaviorSubject, Observable, take, tap} from "rxjs";
import {DmxDocumentService} from "../service/http/dmx-document.service";

@Injectable({
  providedIn: 'root'
})
export class ComboBoxItemStore extends BasicStore {


  private comboBoxItems$ = new BehaviorSubject<ComboBoxItem[]>([]);

  public itemUrls: string[] = [];

  constructor(private dmxDocumentService: DmxDocumentService) {
    super(dmxDocumentService);
    console.log('INFO: WetterIconStore created');
  }


  private init(itemsUrl: string) {
    return this.dmxDocumentService.loadComboBoxItems(itemsUrl).pipe(take(1)).subscribe({
      next: data => {
        if (this.comboBoxItems$.getValue().length === 0) {
          this.comboBoxItems$.next(data);
        } else {
          this.comboBoxItems$.next([...this.comboBoxItems$.getValue(), ...data]);
        }
      }
    })
  }

  public getComboBoxItems() {
    return this.comboBoxItems$.asObservable();
  }

  pushUrlIfNotExists(iconUrl: string) {
    if (!this.itemUrls.includes(iconUrl)) {
      this.itemUrls.push(iconUrl);
      this.init(iconUrl);
    }
  }
}
