import {Component, Input} from '@angular/core';
import {TerminalLabel} from "../../../../model/layout.model";

@Component({
    selector: 'app-terminal-label',
    templateUrl: './terminal-label.component.html',
    styleUrls: ['./terminal-label.component.scss'],
    standalone: true
})
export class TerminalLabelComponent {

  @Input() label?: TerminalLabel;

}
