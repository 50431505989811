<mat-form-field floatLabel="always" class="textbox"
                [style]="textArea.inlineStyle ? layoutElemeneUtil.translateStyle(textArea.inlineStyle):''"
                appearance="outline">
  <mat-label class="custom-form-caption-label">{{ textArea.caption }}</mat-label>
  <textarea [(ngModel)]="textArea.content"
            [maxlength]="textArea.maxChars === 0 ? null : textArea.maxChars!"
            [rows]="textArea.rows" [disabled]="layoutElemeneUtil.ReadonlyMode"
            (ngModelChange)="fire($event)" matInput></textarea>

  <app-character-counter *ngIf="textArea.maxCharsWarning && textArea.maxChars"
                         [maxChars]="textArea.maxChars"
                         [maxCharsWarning]="textArea.maxCharsWarning"
                         [charSequenceLength]="textArea!.content!.length"></app-character-counter>
</mat-form-field>
