import {catchError, EMPTY, Observable, shareReplay, Subject} from "rxjs";

export class ObservableWrapper<T> {
  //Das ist ein optionaler Wrapper für die Service-Requests. Kümmert sich, um das Errorhandling und kann in die LoadingErrorComponent eingebunden werden
  private readonly _errorLoading$ = new Subject<boolean>();
  readonly errorLoading$: Observable<boolean> = this._errorLoading$.pipe(
    shareReplay(1)
  );
  readonly data$: Observable<T>;

  constructor(data: Observable<T>) {
    this.data$ = data.pipe(
      shareReplay(1), catchError(error => {
        console.error('ERROR in ObservableWrapper()', error);
        this._errorLoading$.next(true);
        return EMPTY;
      })
    );
  }
}
