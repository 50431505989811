import {Component, Input} from '@angular/core';
import {ThemePalette} from "@angular/material/core";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {NgIf} from "@angular/common";

@Component({
    selector: 'app-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss'],
    standalone: true,
  imports: [MatProgressSpinnerModule, NgIf]
})
export class LoadingSpinnerComponent {

  @Input() color: ThemePalette = 'accent';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() strokeWidth: number = 6;
  @Input() style?: string;

  getBySize() {
    switch (this.size) {
      case 'small':
        return 25;
      case 'large':
        return 75;
    }
    return 50;
  }
}
