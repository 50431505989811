import {Component, OnDestroy} from '@angular/core';
import {DocumentModel} from "../../../model/document.model";
import {ActivatedRoute, Router} from "@angular/router";
import {delay, first, map, Subject, takeUntil, tap} from "rxjs";
import {DmxDocumentService} from "../../../service/http/dmx-document.service";
import {DocumentDetailActionComponent} from "./document-detail-action/document-detail-action.component";
import {LayoutElementUtil} from "../../../service/layout/layout-element.service";
import {ObservableWrapper} from "../../util/observable-wrapper";
import {MatDialog} from "@angular/material/dialog";
import {RfaStore} from "../../../store/rfa/rfa.store";
import {EventService} from "../../../service/event.service";
import {DocumentDetailElementsComponent} from './document-detail-elements/document-detail-elements.component';
import {MatTabsModule} from '@angular/material/tabs';
import {NgIf, NgFor, NgTemplateOutlet, AsyncPipe} from '@angular/common';
import {LoadingErrorComponent} from '../../common/loading/loading-error/loading-error.component';
import {P_DOCUMENTS} from "../../../app.routes";
import {fadeIn} from "../../../animations/animations";
import {Tab} from "../../../model/layout.model";


@Component({
  selector: 'app-document-detail',
  templateUrl: './document-detail.component.html',
  styleUrls: ['./document-detail.component.scss'],
  animations: [fadeIn],
  standalone: true,
  imports: [
    LoadingErrorComponent,
    NgIf,
    MatTabsModule,
    NgFor,
    NgTemplateOutlet,
    DocumentDetailElementsComponent,
    AsyncPipe,
  ],
})
export class DocumentDetailComponent implements OnDestroy {

  public wetterTerminalDocumentWrapper: ObservableWrapper<DocumentModel> | null = null;
  public selectedTabIndex: number = 0;

  public documentId: string = 'new';
  public selectedRfaKuerzel!: string;
  public readOnly: boolean = false;

  private destroyer$: Subject<boolean> = new Subject<boolean>();

  constructor(private activatedRoute: ActivatedRoute, private readonly restService: DmxDocumentService,
              private dialog: MatDialog, private eventService: EventService,
              private rfaStore: RfaStore, private route: Router, public layoutElementUtil: LayoutElementUtil) {

    //ABBONEIREN AUF DIE ÄNDERUNG DER AUSGEWÄHLTEN RFA
    this.rfaStore.getSelectedRfa().pipe(takeUntil(this.destroyer$), map((rundfunkanstalt) => {
      if (rundfunkanstalt) {
        this.selectedRfaKuerzel = rundfunkanstalt.kuerzel;
        console.log("CONSTRUCTOR DocumentDetailComponent: " + this.selectedRfaKuerzel);
        //ABBONNIERT SICH AUF DIE ROUTE UND BEZIEHT AUS DEN PARAMS DIE DOCUMENTID & GGF. DAS COPYPROFILE
        this.activatedRoute.queryParams
          .pipe(takeUntil(this.destroyer$))
          .subscribe({

            next: params => this.getDocument(this.documentId = params['documentId'], params['copyProfile'])
            ,
            error: err => console.error(`ERROR: fetching queryParams for documentId`, err),
          });
      }
    })).subscribe();


    //ABBONNIERT SICH AUF DEN EVENTSERVICE UND ÖFFNET DAS ACTION SHEET
    this.eventService.getShowPublishActions().pipe(takeUntil(this.destroyer$)).subscribe({
      next: (data) => {
        if (data.show) this.showActions();
      }
    });
  }

  ngOnDestroy() {
    if (!this.destroyer$.closed) {
      this.destroyer$.next(true);
      this.destroyer$.unsubscribe();
    }
  }

  /**
   * Wenn die documentId === "new", dann wird ein neues Dokument erstellt bzw. ein leeres Template geholt
   * @param documentId
   * @private
   */
  private getDocument(documentId: string, copyProfile?: string) {
    this.wetterTerminalDocumentWrapper = new ObservableWrapper(this.restService.loadDocumentById(this.selectedRfaKuerzel, documentId, copyProfile)
      .pipe(first(), tap((data) => this.documentId = data.header.documentId as string)));
  }

  showActions() {
    this.wetterTerminalDocumentWrapper?.data$.subscribe({
      next: (document) => this.openActionSheet(document)
    });
  }

  /**
   * Öffnet das ActionSheet/Veröffentlichungsmenü für das Dokument
   * @param document
   * @private
   */
  private openActionSheet(document: DocumentModel) {

    const actionSheet = this.dialog.open(DocumentDetailActionComponent, {
      width: '750px',
      height: '500px',
      closeOnNavigation: true,
      data: {
        wetterTerminalDocument: document,
        workflowFilterCodes: document.tabs[this.selectedTabIndex].workflowFilterCodes,
      }
    });

    actionSheet.afterClosed().pipe(first()).subscribe({
      next: (value) => {
        if (value) this.route.navigate([`${P_DOCUMENTS}/${this.selectedRfaKuerzel}`]);
      }
    });
  }

  reload = () => this.getDocument(this.documentId);
  protected readonly indexedDB = indexedDB;

  public getIndexOfNonTabElement(tab:Tab):number {

    if(tab.containerList && tab.containerList.length > 0 && tab.containerList[0].containerType === 'Tab') {
      for(let i = 0; i<tab.containerList.length; i++) {
        if(tab.containerList[i].containerType !== 'Tab') {
          return i;
        }
      }
    }
    return -1;
  }
}
