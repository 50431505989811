import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {map, Observable, Subject, take, takeUntil, tap} from "rxjs";
import {ComboBox} from "../../../../model/layout.model";
import {ComboBoxItem} from "../../../../model/document.model";
import {LayoutElementUtil} from "../../../../service/layout/layout-element.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ComboBoxItemStore} from "../../../../store/combo-box-item.store";
import { FormControl, Validators, FormsModule } from "@angular/forms";
import {ScreenSizeObserverService} from "../../../../service/layout/screen-size-observer.service";
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import {NgIf, NgFor, AsyncPipe, NgOptimizedImage} from '@angular/common';

@Component({
    selector: 'app-combo-box',
    templateUrl: './combo-box.component.html',
    styleUrls: ['./combo-box.component.scss'],
    standalone: true,
  imports: [NgIf, MatFormFieldModule, MatSelectModule, FormsModule, MatIconModule, NgFor, MatOptionModule, MatButtonModule, AsyncPipe, NgOptimizedImage]
})
export class ComboBoxComponent implements OnInit {

  @Input() comboBox!: ComboBox;
  @Output() copyEvent: EventEmitter<ComboBoxItem> = new EventEmitter<ComboBoxItem>();

  private readonly comboBoxItems$: Observable<ComboBoxItem[]>;
  comboBoxItemsWithFilter$: Observable<ComboBoxItem[]>;

  constructor(protected layoutElementUtil: LayoutElementUtil,
              private comboBoxItemStore: ComboBoxItemStore,
              private snackbar: MatSnackBar) {

    this.layoutElementUtil.copyComboBoxItemEvent.pipe(map(data => data as boolean)).subscribe({
      next: success => {
        success ? this.setSelectedItem() : this.snackbar.open('Kopieren erfolglos')
      },
      error: err => this.snackbar.open(`Kopiervorgang fehlgeschlagen ${err}`, 'OK')
    });

    this.comboBoxItemsWithFilter$ = this.comboBoxItems$ = this.comboBoxItemStore.getComboBoxItems()
      .pipe(map(items => items.filter(item => {
        return this.comboBox.itemsUrl.endsWith(item.itemType);
      })), tap(() => this.setSelectedItem()));


  }

  ngOnInit() {
    console.log('init ComboBoxComponent')
    this.comboBoxItemStore.pushUrlIfNotExists(this.comboBox.itemsUrl);
  };


  trackByFn = (index: any, item: ComboBoxItem) => item.code;

  copy = () => this.copyEvent.emit(this.comboBox?.selectedItem);

  /**
   * Filtert die Items nach dem eingegebenen Code (hr-code)
   * @param $event
   */
  filterItems($event: KeyboardEvent | FocusEvent) {
    if ($event instanceof FocusEvent) {
      this.comboBoxItemsWithFilter$ = this.comboBoxItems$;
    } else {
      this.comboBoxItemsWithFilter$ = this.comboBoxItems$.pipe(map(items => items.filter(item => item.code.toString().includes($event.key.toString()))));
      if ($event.key === 'Escape' || $event.key === 'Backspace') this.comboBoxItemsWithFilter$ = this.comboBoxItems$;
    }
  }

  /**
   * Setzt das selektierte Item, wenn das Item bereits in der Liste vorhanden ist
   * @private
   */
  private setSelectedItem() {
    this.comboBoxItems$.pipe(take(1), map((items) => {
      const item = items.find(i => i.code === this.comboBox?.selectedItem?.code);
      if (item) {
        this.comboBox.selectedItem = item;
        this.comboBox.selectedItem.selected = true;
      }
    })).subscribe();
  }
}
