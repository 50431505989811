<div class="landing-page-container">

  @if (!(loading$ | async)) {
    @for (rfa of rfaList; track rfa.kuerzel) {
      <mat-card [class.disabled]="rfa.test" routerLink="/documents/{{rfa?.kuerzel}}" [@fadeIn]="'state'"
                (click)="onRfaSelect(rfa)"
                class="landing-page-card mat-elevation-z2">
        <mat-card-header *ngIf="rfa" class="landing-page-card-header">
          <mat-card-title>{{ rfa.kuerzel.toUpperCase() }}</mat-card-title>
          <mat-card-subtitle>{{ rfa.langName }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <img [@fadeIn]="'state'" mat-card-image *ngIf="rfa.logoPath as image"
               class="landing-page-card-image" width="250" [src]="image"
               alt="Logo der RFA">
        </mat-card-content>
      </mat-card>
    }
  } @else {
    <app-loading-skeleton [count]="10"></app-loading-skeleton>
  }
</div>
