import {Routes} from '@angular/router';
import {MsalGuard} from "@azure/msal-angular";
import {environment} from "../environments/environment";
import {DocumentDetailComponent} from "./component/document/document-detail/document-detail.component";
import {DocumentTableComponent} from "./component/document/document-table/document-table.component";
import {LandingPageComponent} from "./component/common/landing-page/landing-page.component";
import {ErrorPageComponent} from "./component/common/error-page/error-page.component";

const GUARD = environment.enableAuth ? [MsalGuard] : [];

export const P_ERROR = '/error-page';
export const P_LANDING = '/landing-page';
export const P_DOCUMENTS = '/documents';

export const P_DOCUMENTS_DETAILS = '/details';

export const APP_ROUTES: Routes = [{
  path: 'error-page',
  component: ErrorPageComponent
},
  {
    path: 'landing-page',
    canActivate: GUARD,
    component: LandingPageComponent
  },
  {
    path: '',
    redirectTo: 'landing-page',
    pathMatch: 'full'
  },
  {
    path: 'documents/:rfa',
    canActivate: GUARD,
    component: DocumentTableComponent
  },
  {
    path: 'documents/:rfa/details',
    canActivate: GUARD,
    component: DocumentDetailComponent,
  },
  {
    path: '**',
    redirectTo: 'error-page',
    pathMatch: 'full'
  }

];

