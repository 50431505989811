import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {NgIf} from '@angular/common';
import {fadeIn} from "../../../animations/animations";

@Component({
  selector: 'app-display-message',
  templateUrl: './display-message.component.html',
  styleUrls: ['./display-message.component.scss'],
  standalone: true,
  animations: [fadeIn],
  imports: [NgIf, MatIconModule, MatButtonModule]
})
export class DisplayMessageComponent {

  @Input() message: string = '';
  @Input() visible: boolean = false;
  @Input() type: string = 'success' || 'error' || 'warning' || 'info';
  @Output() private visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();


  close() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }
}
