import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ObservableWrapper} from "../../../util/observable-wrapper";
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-loading-error',
    templateUrl: './loading-error.component.html',
    styleUrls: ['./loading-error.component.scss'],
    standalone: true,
    imports: [NgIf, MatButtonModule, MatIconModule, LoadingSpinnerComponent, AsyncPipe]
})
export class LoadingErrorComponent {

  // @Input() data$: Observable<any> = new Observable<any>();
  @Input() message: string = 'Fehler beim Laden des Dokuments';

  @Output() private onReload: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() loadingWrapper: ObservableWrapper<any> | null | undefined = null;

  constructor() {
  }

  reload() {
    this.onReload.emit(true);
  }

}
