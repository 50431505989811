<div class="document-detail-actions-header">
  <mat-icon class="document-detail-actions-header-icon">
    settings
  </mat-icon>
  <span class="document-detail-actions-header-title">
    Veröffentlichungseinstellungen
  </span>
  <span class="document-detail-actions-header-text">[{{ data.wetterTerminalDocument.header.documentId }}]</span>
</div>
<app-divider></app-divider>
<!-- Lade Spinner und Erfolgs-Icon -->

@if (loading && !saved) {
  <app-loading-spinner></app-loading-spinner>
} @else if (saved) {
  <mat-icon [@fadeIn]="'state'" class="task-complete-icon">task_alt</mat-icon>
  <app-display-message [visible]="true" type="success"
                       [message]="'Dokument erfolgreich veröffentlicht!'"></app-display-message>
}

@if (!loading && !saved && data.workflowFilterCodes) {
  <div class="document-detail-actions">
    <mat-stepper>

      <!-- Custom Icons -> edit und done sind die default icons. hier bei bedarf ändern: -->
      <ng-template matStepperIcon="edit">
        <mat-icon>edit</mat-icon>
      </ng-template>

      <ng-template matStepperIcon="done">
        <mat-icon>done</mat-icon>
      </ng-template>

      <!-- bei allen steps den Stift setzen -->
      <ng-template matStepperIcon="workflow">
        <mat-icon>edit</mat-icon>
      </ng-template>

      <ng-template matStepperIcon="vordatieren">
        <mat-icon>edit</mat-icon>
      </ng-template>

      <ng-template matStepperIcon="zusammenfassung">
        <mat-icon>edit</mat-icon>
      </ng-template>

      <!-- Workflow/Inhalt -->
      <mat-step label="workflow"
                state="workflow"
                errorMessage="Bitte wählen Sie einen Workflow!"
                [stepControl]="workflowFormGroup">
        <ng-template matStepLabel>Workflow/Inhalt</ng-template>
        <app-workflow-picker [disabled]="!notPublishedYet()" [workflowFilterCodes]="data.workflowFilterCodes"
                             [control]="workflowFormGroup.controls['workflowCtrl']"
                             [(selectedWorkflow)]="data.wetterTerminalDocument.header.workflow">
        </app-workflow-picker>
        <div class="document-detail-action-btn-group">
          <button mat-button color="primary" matStepperNext>
            Weiter
            <mat-icon iconPositionEnd>navigate_next</mat-icon>
          </button>
        </div>
      </mat-step>

      <!-- Vordatieren -->
      <mat-step
        [optional]="true"
        label="vordatieren"
        state="vordatieren"> <!-- standard icon: schedule_send -->
        <ng-template
          matStepLabel>Vordatieren
        </ng-template>
        <div style="margin-top: 15px" class="document-detail-actions-group">
          <app-datetime-picker [showDefaultTime]="false" [showClearButton]="true" [disabled]="!notPublishedYet()"
                               [(date)]="data.wetterTerminalDocument.header.published"></app-datetime-picker>
        </div>
        <div class="document-detail-action-btn-group">

          <button color="accent" mat-button matStepperPrevious>
            <mat-icon>navigate_before</mat-icon>
            Zurück
          </button>
          <button color="primary" mat-button matStepperNext>
            <mat-icon iconPositionEnd>navigate_next</mat-icon>
            Weiter
          </button>
        </div>
      </mat-step>

      <!-- Zusammenfassung -->
      <mat-step
        label="zusammenfassung"
        state="zusammenfassung">
        <ng-template matStepLabel>Veröffentlichen</ng-template>
        <mat-accordion class="document-detail-actions-summary-header">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <b>Zusammenfassung</b>
              </mat-panel-title>
              <mat-panel-description *ngIf="data.wetterTerminalDocument.header.workflow">
                {{ data.wetterTerminalDocument.header.workflow.description }}
                <mat-icon>summarize</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <app-document-table-expansion-detail [type]="'settings'"
                                                 [documentHeader]="data.wetterTerminalDocument.header">
            </app-document-table-expansion-detail>
          </mat-expansion-panel>
        </mat-accordion>

        <div class="document-detail-actions-group">
          <!-- Datensatz speichern oder veröffentlichen/vordatieren -->
          @if (statusList$ | async; as statusList) {
            <div *ngIf="notPublishedYet()" class="document-detail-action-btn-group"
                 style="flex-wrap: wrap; justify-content: space-evenly">

              @for (status of statusList; track $index) {
                <button [disabled]="!data.wetterTerminalDocument.header.workflow"
                        class="document-detail-action-btn"
                        mat-stroked-button
                        color="primary" (click)="publish(status)">
                  {{ status?.kuerzel }}
                  <mat-icon color="accent" class="status-icon">{{ status?.icon }}</mat-icon>
                </button>
              } @empty {
                <p>Keine Status gefunden!</p>
              }
            </div>
          } @else {
            <app-loading-spinner></app-loading-spinner>
          }
        </div>
        <div class="document-detail-action-btn-group">
          <button color="accent" mat-button matStepperPrevious>
            <mat-icon>navigate_before</mat-icon>
            Zurück
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
}


