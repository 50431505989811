import {Component, OnDestroy} from '@angular/core';
import {map, take} from "rxjs";
import {RundfunkAnstalt} from "../../../model/document.model";
import {fadeIn, slideToCenter} from "../../../animations/animations";
import {RfaStore} from "../../../store/rfa/rfa.store";
import { Router, RouterLink } from "@angular/router";
import {ImageService} from "../../../service/image.service";
import { MatCardModule } from '@angular/material/card';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { LoadingSkeletonComponent } from '../loading/loading-skeleton/loading-skeleton.component';
import {P_ERROR} from "../../../app.routes";

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss'],
    animations: [fadeIn, slideToCenter],
    standalone: true,
    imports: [LoadingSkeletonComponent, NgIf, NgFor, MatCardModule, RouterLink, AsyncPipe]
})
export class LandingPageComponent implements OnDestroy {

  rfaList: RundfunkAnstalt[] = [];

  loading$;
  error$;

  private subscription$;

  constructor(private rfaStore: RfaStore, private router: Router, private imageService: ImageService) {

    this.loading$ = this.rfaStore.isLoading();
    this.error$ = this.rfaStore.getError();

    this.subscription$ = this.rfaStore.getRfasFromStore()
      .pipe(map((data) => data as RundfunkAnstalt[]),
      map((data) => {
        const rfaList: RundfunkAnstalt[] = JSON.parse(JSON.stringify(data));
        this.imageService.cacheUrls = rfaList.map((rfa) => rfa.kuerzel);
        rfaList
          .map((rfa) => this.imageService.getImage(rfa.kuerzel)
            .pipe(take(1))
          .subscribe((imageUrl) => rfa.logoPath = imageUrl));
        return rfaList;
      }))
      .subscribe({
        next: (data) => this.rfaList = data,
        error: (err) => {
          this.router.navigate([P_ERROR], {
            queryParams: {
              message: 'Fehler beim Laden der Rundfunkanstalten',
              error: err,
              status: 500
            }
          });
        },
      });
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  onRfaSelect(selectedRfa: RundfunkAnstalt) {
    this.rfaStore.setSelectedRfa(selectedRfa);
  }
}
