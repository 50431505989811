<!-- Action Header enthält die Stati Filter und den 'Neu' Button -->
<div class="document-table-action-header">

  @if (!(loading$ | async)) {
    <app-status-filter *ngIf="(statusList$ | async) as statusList" [statusList]="statusList"
                       (filterChange)="applyFilter($event)"></app-status-filter>
  } @else {
    <app-loading-spinner class="loading-spinner" [style]="'margin-top: 5px'" [color]="'primary'"
                         [size]="'small'"></app-loading-spinner>
  }

  <span class="spacer"></span>
  <!-- Neu Button -->
  <button routerLink="details" [queryParams]="{documentId: 'new'}" matRipple mat-raised-button
          class="document-table-action-button">
    <mat-icon style="font-size: 24px; height: 24px; width: 20px" color="primary">add</mat-icon>
    <span class="document-table-action-button-text">NEU</span>
  </button>
  <button (click)="refresh()" style="width: fit-content" mat-icon-button>
    <mat-icon color="primary">refresh</mat-icon>
  </button>
</div>


