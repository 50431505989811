import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TextArea, TextAreaContainer} from "../../../../model/layout.model";
import {TextAreaComponent} from "../text-area/text-area.component";
import {LayoutElementUtil} from "../../../../service/layout/layout-element.service";
import {NgForOf, NgIf} from "@angular/common";
import {TerminalLabelComponent} from "../terminal-label/terminal-label.component";
import {CharacterCounterComponent} from "../character-counter/character-counter.component";

@Component({
  selector: 'app-text-area-container',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    TerminalLabelComponent,
    TextAreaComponent,
    CharacterCounterComponent
  ],
  templateUrl: './text-area-container.component.html',
  styleUrl: './text-area-container.component.scss'
})
export class TextAreaContainerComponent {

  @Input() textAreaContainer!: TextAreaContainer;
  charCount:number = 0;

  constructor(public layoutElementUtil: LayoutElementUtil) {

  }

  computeCharcount(count:number) {
    this.charCount = this.charCount + count;
    // todo: durch alle Textareas gehen und max-length setzen
    // todo: ACHTUNG: funktioniert nicht, wenn man kopiert
    /*if(this.textAreaContainer && this.textAreaContainer.maxChars) {
      if(this.charCount >= this.textAreaContainer.maxChars) {
        this.disableTextareas();
      } else {
        this.enableTextareas();
      }
    }*/
  }

  disableTextareas() {
    this.textAreaContainer.containerList?.forEach(container => {
      const textArea = this.layoutElementUtil.asTextArea(container);
      if(textArea) {
        if(textArea.content) {
          if(this.textAreaContainer.maxChars && textArea.content.length < this.textAreaContainer.maxChars) {
            textArea.maxChars = textArea.content.length;
          } else {
            textArea.maxChars = this.textAreaContainer.maxChars;
            textArea.content = textArea.content.substring(0, this.textAreaContainer.maxChars?this.textAreaContainer.maxChars : 0);
            this.charCount=this.textAreaContainer.maxChars?this.textAreaContainer.maxChars:0;
          }
          console.log("DISABLE: " + textArea.caption + " COUNT: " + textArea.content.length + " MAXCHARS: " + textArea.maxChars)
        } else {
          textArea.content = '';
          textArea.maxChars = 0;
          //console.log("DISABLE: " + textArea.caption + " COUNT: " + textArea.content.length + " MAXCHARS: " + textArea.maxChars)
        }
      }
    })
  }

  enableTextareas() {
    this.textAreaContainer.containerList?.forEach(container => {
      const textArea = this.layoutElementUtil.asTextArea(container);
      if(textArea) {
        //console.log("ENABLE: " + textArea.caption + " COUNT: " + textArea.content?.length?textArea.content?.length:0)
        textArea.maxChars = null;
      }
    })
  }

}
