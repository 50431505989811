import {Injectable} from "@angular/core";
import {BehaviorSubject, catchError, Observable, shareReplay, take} from "rxjs";
import {RundfunkAnstalt} from "../../model/document.model";
import {RfaCache} from "./rfa-cache.service";
import {DmxDocumentService} from "../../service/http/dmx-document.service";
import {BasicStore} from "../basic.store";


@Injectable({
  providedIn: 'root'
})
export class RfaStore extends BasicStore {


  private rfas$ = new BehaviorSubject<RundfunkAnstalt[]>([]);
  private selectedRfa$ = new BehaviorSubject<RundfunkAnstalt | undefined>(undefined);

  constructor(private dmxDocumentService: DmxDocumentService, private rfaCache: RfaCache) {
    super(dmxDocumentService);
    this.loadRfas();
  }

  private loadRfas() {
    this.startLoad();
    this.dmxDocumentService.loadAllRfas().pipe( catchError((err) => {
      console.error(`ERROR: loadRfas failed: ${err}`);
      this.handleError(err);
      return [];
    }), take(1), shareReplay({refCount: true, bufferSize: 1})).subscribe({
      next: data => {
        console.log(`INFO: fetched all rfas`);
        this.publishRfas(data);
        this.checkForCachedRfa(data);
        this.stopLoad();
      },
      error: err => {
        console.log(`ERROR: loadRfas failed: ${err}`);
        this.handleError(err)
      },
    });
  }

  private checkForCachedRfa(data: RundfunkAnstalt[]) {
    if (!this.selectedRfa$ || !this.selectedRfa$.getValue()) {
      const cachedRfa = this.rfaCache.getSelectionFromCache();
      console.log(`INFO: selectedRfa from cache: ${JSON.stringify(cachedRfa)}`);
      if (cachedRfa) this.selectedRfa$.next(cachedRfa);
      else {
        console.log(`INFO: no selectedRfa in cache, setting default to ard`);
        this.selectedRfa$.next(data.find(rfa => rfa.kuerzel === 'ard'));
      }
    }
  }

  getRfasFromStore = (): Observable<RundfunkAnstalt[]> => this.rfas$.asObservable();

  publishRfas = (rfas: RundfunkAnstalt[]) => this.rfas$.next(rfas);

  getSelectedRfa = () => this.selectedRfa$.asObservable();

  setSelectedRfa(rfa: RundfunkAnstalt) {
    this.rfaCache.cacheSelection(rfa);
    this.selectedRfa$.next(rfa);
  }

}
