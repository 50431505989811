import {Component, Input} from '@angular/core';
import {TerminalList} from "../../../../model/layout.model";
import {LayoutElementUtil} from "../../../../service/layout/layout-element.service";
import {TerminalLabelComponent} from '../terminal-label/terminal-label.component';
import {ComboBoxComponent} from '../combo-box/combo-box.component';
import {TextFieldComponent} from '../text-field/text-field.component';
import {NgFor, NgIf} from '@angular/common';
import {TemperatureTextFieldComponent} from "../temperature-text-field/temperature-text-field.component";

@Component({
    selector: 'app-terminal-list',
    templateUrl: './terminal-list.component.html',
    styleUrls: ['./terminal-list.component.scss'],
    standalone: true,
  imports: [NgFor, NgIf, TextFieldComponent, ComboBoxComponent, TerminalLabelComponent, TemperatureTextFieldComponent]
})
export class TerminalListComponent {

  @Input() terminalList?: TerminalList;

  constructor(public layoutElementUtil: LayoutElementUtil) {
  }

}
