<div *ngIf="loadingWrapper && loadingWrapper.errorLoading$ | async as error;else loading">
  <div class="error-loading-container" *ngIf="error.valueOf()">
    <h2 class="error-message">{{message}}</h2>
    <button class="error-loading-reload-btn" mat-stroked-button (click)="reload()">
      <mat-icon color="primary">refresh</mat-icon>
      Erneut Laden
    </button>
  </div>
</div>
<ng-template #loading>
  <app-loading-spinner></app-loading-spinner>
</ng-template>
